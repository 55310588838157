import autoComplete from "@tarekraafat/autocomplete.js"
import $assist from "./Assistant"

export default function searchInit() {
  const searchSelector = 'js-search';
  const resultsClass = 'home__search-form-route-results';
  const resultsShowClass = 'results-show';

  let href = '';

  if (window.location.hostname === 'localhost') href = '/mockData/search.json';
  else if (window.location.hostname === '2082.itfactory.site') href = '/local/mockData/search.json';


  $assist(`.${searchSelector}`).each(block => {
    let input = block.querySelector(`.${searchSelector}-input`);
    let resultsBox = block.querySelector(`.${searchSelector}-results`);

    if (input === null || resultsBox === null) return false;

    input.addEventListener('autoComplete', function (event) {

      if (event.detail.input === '') {
        input.classList.remove(resultsShowClass);
        resultsBox.classList.remove(resultsShowClass);
      }
    });

    ['focus', 'blur', 'keyup'].forEach(function (eventType) {
      input.addEventListener(eventType, () => {
        if (eventType === 'blur') {
          input.classList.remove(resultsShowClass);
          resultsBox.classList.remove(resultsShowClass);

        } else if (eventType === 'focus' || eventType === 'keyup') {
          if (input.value !== '') {
            input.classList.add(resultsShowClass);
            resultsBox.classList.add(resultsShowClass);
          }
        }
      });
    });

    searchRequest(input, resultsBox);
  });

  function searchRequest(input, resultsBox) {
    let id = input.getAttribute('id') || 'id';

    new autoComplete({
      data: {
        src: async () => {
          const query = input.value;
          const source = await fetch(`${href}?action=searchQuery&id=${id}&query=${query}`);
          const data = await source.json();

          return data;
        },
        key: ["title"],
        cache: false
      },
      query: {
        manipulate: (query) => {
          return query;
        }
      },
      trigger: {
        event: ['input', 'focusin']
      },
      placeHolder: 'Начните вводить',
      selector: `#${id}`,
      threshold: 0,
      maxResults: 99,
      highlight: false,
      debounce: 100,
      searchEngine: "strict",
      resultsList: {
        render: true,
        container: source => {
          source.classList.add(`${resultsClass}-list`);
        },
        destination: resultsBox.querySelector(`.${resultsClass}-inner`),
        position: "afterend",
        element: "div"
      },
      resultItem: {
        content: (data, source) => {
          source.classList.add(`${resultsClass}-list-item`);
          source.innerHTML = data.match;
        },
        element: "div"
      },
      noResults: () => {
        const result = document.createElement('div');
        result.setAttribute('class', 'no_result');
        result.setAttribute('tabindex', '1');
        result.innerHTML = 'Ничего не найдено';

        resultsBox.querySelector(`.${resultsClass}-list`).appendChild(result);
      },
      onSelection: feedback => {
        input.value = feedback.selection.match;
        fetch(`${href}?action=selectRoute&id=${feedback.selection.value.id}`).then(response => console.log(response));
      }
    });
  }
};