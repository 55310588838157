import $assist from "../utilities/Assistant";
import {variables as $v} from "../variables";
import {gsap} from "gsap";

gsap.defaults({duration: $v.gsapDefaultDuration});

export default function initDropdown(closeAnother = false) {

  const
    activeClass = 'open',
    rootSelector = '.js-dropdown-item',
    buttonSelector = '.js-dropdown-button',
    contentSelector = '.js-dropdown-content',
    textSelector = '.js-dropdown-button-text'
  ;

  if ($assist(rootSelector).els.length > 0) {
    function hide(target, root) {
      gsap.to(target, {height: 0});
      root.removeClass(activeClass);

      root.each(el => {
        let text = el.querySelector(textSelector);

        if (text) {
          console.log(text.getAttribute('data-default-text'));
          text.innerHTML = text.getAttribute('data-default-text') || 'Подробнее';
        }
      });
    }

    function toggle(root) {
      let content = $assist(contentSelector, root);
      let text = $assist(textSelector, root);
      let altText = (text.el !== undefined) ? text.el.getAttribute('data-alt-text') : 'Свернуть';

      if (root.classList.contains(activeClass)) {
        hide(content.el, $assist(root));

      } else {
        gsap.to(content.el, {height: 'auto', onComplete: () => lastOpenedDd = root});
        root.classList.add(activeClass);
        if (text.el !== undefined) text.el.innerHTML = altText;
      }
    }

    let lastOpenedDd = null, activated = false;

    $assist(document).on($v.mainEvent, event => {

      const {target} = event;

      activated = target.matches(rootSelector) || target.closest(rootSelector);

      if (activated) {

        if (target.matches(buttonSelector) || target.closest(buttonSelector)) toggle(target.closest(rootSelector));

        let currentRootDd = target.closest(rootSelector) || target;

        if (closeAnother) {
          if (lastOpenedDd) {
            if (
              (currentRootDd !== lastOpenedDd)
              &&
              (currentRootDd.dataset.group === lastOpenedDd.dataset.group)
            ) hide(lastOpenedDd.querySelector(contentSelector), $assist(lastOpenedDd));
          }
        }

      } else {
        hide(contentSelector, $assist(rootSelector));

      }
    });
  }

}

