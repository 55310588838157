import {fadeOut} from './helpers'

export function domReady(cb) {
	if ( 'function' !== typeof cb ) {
		return;
	}
	if (document.readyState === 'complete' ) {
		return cb();
	}
	document.addEventListener( 'DOMContentLoaded', cb, false );
}
export function alert(message) {
  let dur = 2000;
  let notify = document.querySelector('.notify');
  let alert = document.createElement('div');

  function closeAlert() {
    fadeOut(alert, true);
  }

  if (notify === null) {
    notify = document.createElement('div');
    notify.classList.add('notify');
    document.body.appendChild(notify);
  }

  alert.innerHTML = `<div class="alert">
                       <div class="alert__inner">${message}</div>
                     </div>`;

  notify.appendChild(alert);

  setTimeout(closeAlert, dur);
}