export default function timer(callback) {
  const containerSelector = '.js-timer';
  let container = document.querySelector(containerSelector);

  if (container) {
    let duration = Number(container.getAttribute('data-time')) || 1;
    let timer = duration * 60;
    let minutes, seconds;

    let caption = container.querySelector(`${containerSelector}-caption`);
    let counter = container.querySelector(`${containerSelector}-counter`);

    if (counter === null) return false;

    container.classList.add('timer-start');

    setInterval(() => {
      minutes = parseInt(timer / 60, 10) || 0;
      seconds = parseInt(timer % 60, 10) || 0;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      counter.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;

        container.classList.remove('timer-start');
        if (caption) caption.remove();
        counter.remove();
      }
    }, 1000);
  }
}