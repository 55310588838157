import {gsap} from "gsap"
import $assist from "../utilities/Assistant"
import punycode from "punycode"

const
	sbw = getScrollbarWidth(),
	body = $assist('body')
;

function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}
/*-----------local end------------*/

export function createSvg(tag, attrs) {
	let el = document.createElementNS('http://www.w3.org/2000/svg', tag);
	for (let key in attrs) el.setAttribute(key, attrs[key]);
	return el;
}
export function getScrollbarWidth() {
	return window.innerWidth - document.documentElement.clientWidth;
}
export function blockedScroll(action) {

	switch (action) {
		case 'set':
			gsap.set(body.el, {
				overflow: 'hidden',
				paddingRight: sbw
			});
			break;
		case 'remove':
			gsap.set(body.el, {
				clearProps: 'overflow,paddingRight'
			});
			break;
		default:
			console.error('Missing argument in blockedScroll function')
	}

	return sbw;

}
export function deepMergeObject(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				deepMergeObject(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return deepMergeObject(target, ...sources);
}

export function slideUp(el, dur = 0.4) {
  gsap.to(el, {
    height: '0',
    ease: 'power3',
    duration: dur,
    onStart: () => {
      el.classList.remove('open');
    }
  });
}

export function slideDown(el, dur = 0.4) {
  gsap.to(el, {
    height: 'auto',
    ease: 'power3',
    duration: dur,
    onComplete: () => {
      el.classList.add('open');
    }
  });
}

export function fadeOut(el, remove, callback){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {

      el.style.display = "none";
      if (remove) el.remove();
      if (callback) callback();

    } else {
      requestAnimationFrame(fade);
    }
  })();
}

export function fadeIn(el, display, callback){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    let val = parseFloat(el.style.opacity);
    if (!((val += .1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);

      if (callback) callback();
    }
  })();
}

export function validateEmailChar(email) {
  email.addEventListener('keyup', (e) => {
    const re = /[А-Яа-яЁё\s!#\$%\^\&\*\(\)=\+`~\{\}\[\],\\\|\/;:?'"№]/g;
    let str = punycode.toUnicode(email.value);

    if (re.test(str)) {
      email.value = str.replace(re, '');
    }
  });
}
