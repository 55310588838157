import $assist from "./utilities/Assistant";
import {isTouchDevice} from "./utilities/checks";

export const variables = {
	assistantDebugMode: true,
  mainEvent: isTouchDevice() ? 'touchstart' : 'click',
	body: $assist('body'),
	gsapDefaultDuration: .25,
	customResizeEventName: 'global-resize-done',
	breakpoints: {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
	}
};
