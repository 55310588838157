import 'svgxuse'
import './modules/common/'
import './utilities/resizeTrigger'

import {variables as app} from './variables'
import {domReady, alert} from './utilities/callbacks'
import $assist from "./utilities/Assistant"
import {tabsInit, switchInit} from './utilities/tabs'
import searchInit from './utilities/autoComplete'
import {fadeOut, fadeIn, slideDown, slideUp, validateEmailChar} from './utilities/helpers'
import timer from './utilities/timer'
import initDropdown from "./utilities/dropdown";

/* plugins */
import './utilities/rating'
import 'simplebar'
import SimpleBar from 'simplebar'
import MicroModal from 'micromodal'
import IMask from 'imask'
import SlimSelect from 'slim-select'
import {Datepicker, DateRangePicker} from 'vanillajs-datepicker'
import ru from 'vanillajs-datepicker/js/i18n/locales/ru'

Object.assign(Datepicker.locales, ru);

window.tabsInit = tabsInit;
window.switchInit = switchInit;
window.searchInit = searchInit;
window.timerInit = timer;
window.validateEmailChar = validateEmailChar;
window.alert = alert;


window.microModalInit = function () {
  MicroModal.init({
    openTrigger: 'data-modal-open',
    closeTrigger: 'data-modal-close',
    onShow() {
      app.body.el.classList.add('modal-is-open');
      window.tabsInit();
    },
    onClose() {
      app.body.el.classList.remove('modal-is-open');
    }
  });

  // MicroModal.show('create-transport');
};

window.datepickerInit = function () {
  let datepickerAll = document.querySelectorAll('.js-datepicker');
  let rangeDatepickerAll = document.querySelectorAll('.js-range-datepicker');

  let options = {
    prevArrow: '<svg class="svg-icon"><use xlink:href="img/sprite.svg#left-arrow"></use></svg>',
    nextArrow: '<svg class="svg-icon"><use xlink:href="img/sprite.svg#right-arrow"></use></svg>',
    autohide: true,
    language: 'ru',
    todayHighlight: false
  };

  datepickerAll.forEach(el => {
    let formatValue = el.getAttribute('data-format') || 'dd.mm.yyyy';
    let viewValue = 0;
    let maxDateValue = '';
    let minDateValue = 'today';

    if (formatValue === 'yyyy') {
      viewValue = 2;
    }

    if (el.classList.contains('js-datepicker-past')) {
      maxDateValue = 'today';
      minDateValue = '';

    }

    const datepicker = new Datepicker(el, Object.assign(options, {
      format: formatValue,
      startView: viewValue,
      minDate: minDateValue,
      maxDate: maxDateValue,
    }));

    datepicker.setDate('today');
  });

  rangeDatepickerAll.forEach(el => {
    let inputs = el.querySelectorAll('input');

    inputs.forEach(input => {
      input.value = new Date().toLocaleDateString();
    });

    const datepickerRange = new DateRangePicker(el, Object.assign(options, {
      format: el.getAttribute('data-format') || 'dd.mm.yyyy'
    }));
  });
};

window.customSelectInit = function() {
  let selectAll = document.querySelectorAll('.js-customSelect');

  selectAll.forEach(el => {

    let options = {
      select: el,
      showSearch: false,
      beforeClose: () => {
        simpleBar.unMount();
        new SimpleBar(select.slim.list);
      }
    };

    if (el.hasAttribute('multiple')) {
      options = Object.assign(options, {
        placeholder: el.getAttribute('data-placeholder') || 'Выберите из списка',
        allowDeselectOption: true,
        closeOnSelect: false
      });
    }

    let select = new SlimSelect(options);
    let simpleBar = new SimpleBar(select.slim.list);
  });
};

window.numberMaskInit = function() {
  let numberInputs = document.querySelectorAll('.js-only-number');

  numberInputs.forEach(el => {
    IMask(el, {
      mask: Number,
      scale: 0,
      signed: true,
      min: 0,
      max: el.getAttribute('data-max') || 900
    });
  });
};

window.phoneMaskInit = function() {
  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.matches('.js-phone-input')) {
      if (!target.hasAttribute('data-mask-init')) {
        IMask(target, {
          mask: '+7 (000) 000-00-00'
        });

        target.setAttribute('data-mask-init', '');
      }
    }
  });
};

window.rangeNumberInit = function () {
  let rangeNumbers = document.querySelectorAll('.js-range-number');

  rangeNumbers.forEach(range => {
    let inputFrom = range.querySelector('.js-range-number-from');
    let inputTo = range.querySelector('.js-range-number-to');

    if (inputFrom && inputTo) {
      checkValue(inputFrom, inputTo);

      inputFrom.addEventListener('change', () => {
        checkValue(inputFrom, inputTo);
      });

      inputTo.addEventListener('change', () => {
        checkValue(inputFrom, inputTo);
      });
    }

    function checkValue(inputFrom, inputTo) {
      let valueFrom = Number(inputFrom.value) || 0;
      let valueTo = Number(inputTo.value) || 0;

      if (valueFrom > valueTo) {
        inputFrom.value = valueTo;
      }
    }
  });
};

window.range2NumberInit = function () {
  let rangeNumbers = document.querySelectorAll('.js-range');

  rangeNumbers.forEach(range => {
    let inputFrom = range.querySelector('.js-range-prepaid');
    let inputTo = range.querySelector('.js-range-remains');

    if (inputFrom && inputTo) {
      inputFrom.addEventListener('keyup', () => {

        inputTo.value = 100 - Number(inputFrom.value);
      });

      inputTo.addEventListener('keyup', () => {
        inputFrom.value = 100 - Number(inputTo.value);
      });
    }
  });
};

window.slideToggle = function() {
  let itemAll = document.querySelectorAll('.js-slide-item');

  itemAll.forEach(item => {
    let btn = item.querySelector('.js-slide-btn');
    let content = item.querySelector('.js-slide-content');

    if (btn && content) {
      let textBox = btn.querySelector('.js-slide-btn-text');
      let defaultText = 'Развернуть';
      let altText = 'Свернуть';

      if (textBox) {
        defaultText = textBox.innerHTML;
        altText = textBox.getAttribute('data-text');
      }

      btn.addEventListener(app.mainEvent, e => {
        if (content.classList.contains('open')) {
          item.classList.remove('open');
          slideUp(content);
          if (textBox) textBox.innerHTML = defaultText;

        } else {
          item.classList.add('open');
          slideDown(content);
          if (textBox) textBox.innerHTML = altText;
        }
      });
    }
  });
};

window.checkboxInputAll = function() {
  document.addEventListener(app.mainEvent, e => {
    if (e.target.closest('.js-checkbox-all')) {

      let inputAll = e.target.closest('.js-checkbox-all');

      let box = inputAll.closest('.js-checkbox-group');
      let name = inputAll.getAttribute('name') || '';
      let group = Array.from(box.querySelectorAll(`input[name="${name}"]`)).filter(item => !item.classList.contains('js-checkbox-all'));
      let count = group.length || 0;
      let marker = 0;

      inputAll.addEventListener('change', () => {
        if (inputAll.checked) {
          group.forEach(input => {
            input.checked = false;
          });
        }
      });

      group.forEach(input => {
        input.addEventListener('change', () => {
          if (input.checked) {
            inputAll.checked = false;
            // marker++;

            // if (marker === count) {/
              // marker = 0;
              // inputAll.checked = true;
              // group.forEach(input => {
              //   input.checked = false;
              // });
            // }
          }
        });
      });
    }
  });
};

window.menuInit = function() {
  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-menu-btn')) {
      app.body.toggleClass('menu-is-open');
    }
  });
};

window.accInit = function() {
  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-acc-btn')) {
      let item = target.closest('.js-acc-item');

      if (item) {
        item.classList.toggle('open');
      }
    }
  });
};

window.tabBarInit = function() {
  let tabBar = document.querySelector('.js-tabBar');

  if (tabBar === null) return false;

  let itemAll = tabBar.querySelectorAll('.js-tabBar-item');


  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-tabBar-btn')) {
      let item = target.closest('.js-tabBar-item');

      if (item && item.classList.contains('open')) {
        tabBar.classList.remove('open');
        item.classList.remove('open');

      } else {
        itemAll.forEach(item => {
          if (item.classList.contains('open')) {
            item.classList.remove('open');
          }
        });

        tabBar.classList.add('open');
        item.classList.add('open');
      }
    } else {
      itemAll.forEach(item => {
        if (item.classList.contains('open')) {
          item.classList.remove('open');
        }
      });

      tabBar.classList.remove('open');
    }
  });
};

window.slideCardInit = function() {
  let header = document.querySelector('.header');
  const headerHeight = header.getBoundingClientRect().height || 0;

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;
    const position = document.documentElement.scrollTop || 0;

    if (target.closest('.js-slide-card-btn')) {
      let card = target.closest('.js-slide-card');

      if (card) {
        const cardPosition = card.getBoundingClientRect().top || 0;

        if (card.classList.contains('open')) {
          app.body.addClass('is-blocked');
          card.classList.remove('open');

          document.documentElement.scrollTo({
            'behavior': 'smooth',
            'top': position - headerHeight - 10 + cardPosition,
            'left': 0
          });

          setTimeout(() => {
            app.body.removeClass('is-blocked');
          }, 100);
        } else {
          card.classList.add('open');
        }
      }
    }
  });
};

window.cardInit = function() {
  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-card-remove')) {
      let card = target.closest('.js-card');

      if (card) {
        fadeOut(card, true);
      }
    }
  });

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('input.js-card-check')) {
      let card = target.closest('.js-card');

      if (card) {
        card.classList.toggle('card-checked');
        checkCards();
      }
    }

    function checkCards() {
      if ($assist('.js-card.card-checked').els.length > 0) {
        $assist('.js-card-hide').el.removeAttribute('disabled');
      } else {
        $assist('.js-card-hide').el.setAttribute('disabled', 'disabled');
      }
    }
  });

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-card-hide')) {
      let buttonHide = target.closest('.js-card-hide');

      $assist('.js-card.card-checked').each(card => {
        fadeOut(card, false, () => {
          card.classList.remove('card-checked');
          card.classList.add('card-hide');
        });
      });

      buttonHide.setAttribute('disabled', 'disabled');
    }
  });

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest('.js-card-show')) {
      let buttonShow = target.closest('.js-card-show');

      $assist('.js-card.card-hide').each(card => {
        fadeIn(card, 'block', () => {
          card.classList.remove('card-hide');
        });
      });

      $assist('.js-card-hide').el.removeAttribute('disabled');
      buttonShow.setAttribute('disabled', 'disabled');
    }
  });
};

window.ratingInit = function() {
  $('.js-rating').rating((vote, event) => {
    $.ajax({
      url: '',
      type: 'GET',
      data: {
        rate: vote
      }
    });
  });

  document.addEventListener(app.mainEvent, e => {
    if (e.target.closest('.js-rating-tooltip-btn')) {
      let button = e.target.closest('.js-rating-tooltip-btn');
      let modalId = button.getAttribute('data-modal') || '';
      let modal = document.querySelector(`#${modalId}`);

      if (modal) {
        modal.classList.add('is-open');
        dataRequest(modal);

        let close = modal.querySelector('.js-rating-tooltip-close');
        if (close) {
          close.addEventListener(app.mainEvent, e => {
            modal.classList.remove('is-open');
            modal.classList.remove('loaded');
          });
        }
      }
    } else {
      if (!e.target.closest('.js-rating-tooltip')) {
        $assist('.js-rating-tooltip').each(tooltip => {
          tooltip.classList.remove('is-open');
          tooltip.classList.remove('loaded');
        });
      }
    }
  });

  function dataRequest(modal) {
    let href = '';
    if (window.location.hostname === 'localhost' || window.location.hostname === '20822.itfactory.site') href = '/mockData/rating.json';

    $.ajax({
      url: href,
      type: 'GET',
      data: {
        id: modal.getAttribute('id') || 0
      },
      success: (result) => {
        if (result.status === 200) {
          modal.classList.add('loaded');
        }
      }
    });
  }
};

domReady(() => {
  initDropdown(false);
  tabsInit();
  switchInit();
  timerInit();
  tabBarInit();
  menuInit();
  microModalInit();
  rangeNumberInit();

  range2NumberInit(); //todo довести до ума
  cardInit();
  slideCardInit();

  numberMaskInit();
  phoneMaskInit();
  customSelectInit();
  datepickerInit();
  slideToggle();
  accInit();
  searchInit();
  checkboxInputAll();
  ratingInit();

  $assist('input[type="email"]').each(email => {
    validateEmailChar(email);
  });
});


