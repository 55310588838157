import {variables as app} from "../variables";
import $assist from "./Assistant";

export function tabsInit() {
  const tabNavSelector = '.js-tabs-nav-item';
  const tabContentSelector = '.js-tabs-content-item';
  let navItems, contentItems, activeContentItem;

  function checkActiveTab(activeNavItem) {
    if (!activeNavItem) {
      navItems = document.querySelectorAll(tabNavSelector);
      let activeNavItems = Array.from(navItems).filter(navItem => navItem.classList.contains('active'));

      activeNavItems.forEach(activeNavItem => {
        setActiveTab(activeNavItem);
      });

    } else {
      setActiveTab(activeNavItem);
    }

    function setActiveTab(activeNavItem) {
      if (activeNavItem) {
        let container = activeNavItem.closest('.js-tabs');

        if (container) {
          navItems = container.querySelectorAll(tabNavSelector);
          contentItems = container.querySelectorAll(tabContentSelector);

          let id = activeNavItem.getAttribute('data-id') || '';
          activeContentItem = Array.from(contentItems).filter(contentItem => contentItem.getAttribute('data-id') === id);

          if (activeContentItem) {
            [...navItems, ...contentItems].forEach(item => item.classList.remove('active'));

            activeNavItem.classList.add('active');
            activeContentItem[0].classList.add('active');
          }
        }
      }
    }
  }

  checkActiveTab();

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest(tabNavSelector)) {
      checkActiveTab(target.closest(tabNavSelector));
    }
  });
}

export function switchInit() {
  const switchSelector = '.js-switch';
  const switchClass = 'switch-on';
  let options, contentItems, activeContentItem, activeOption, inputs;

  function switchTab(button) {
    let container = button.closest(`${switchSelector}`);

    if (container) {
      options = container.querySelectorAll(`${switchSelector}-option`);
      contentItems = container.querySelectorAll(`${switchSelector}-content-item`);
      activeOption = Array.from(options).filter(option => option.classList.contains('active'));
      inputs = container.querySelectorAll('input');

      if (activeOption) {
        let id = activeOption[0].getAttribute('data-option') || '';
        activeContentItem = Array.from(contentItems).filter(contentItem => contentItem.getAttribute('data-option') === id);

        if (activeContentItem) {
          let activeInputs = activeContentItem[0].querySelectorAll('input');

          inputs.forEach(input => input.removeAttribute('disabled'));
          [...options, ...contentItems].forEach(item => item.classList.add('active'));

          button.classList.toggle(switchClass);
          activeOption[0].classList.remove('active');
          activeContentItem[0].classList.remove('active');
          activeInputs.forEach(input => input.setAttribute('disabled', 'disabled'));
        }
      }
    }
  }

  document.addEventListener(app.mainEvent, event => {
    const {target} = event;

    if (target.closest(`${switchSelector}-btn`)) {
      switchTab(target.closest(`${switchSelector}-btn`));
    }
  });
}
